@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~video-react/styles/scss/video-react";
@import "~react-quill/dist/quill.snow.css";
html {
  scroll-behavior: unset !important;
}
@font-face {
  font-family: WorkSans-ExtraLight;
  src: url("./assets/fonts/WorkSans-ExtraLight.otf") format("opentype");
}
@font-face {
  font-family: "Futura LT Light";
  font-style: normal;
  font-weight: normal;
  src:
    local("Futura LT Light"),
    url("./assets/fonts/FuturaLT-Light.woff") format("woff");
}
@font-face {
  font-family: "Futura LT Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Futura LT Regular"), url("./assets/fonts/Futura-Regular.ttf");
}

:root {
  --bs-body-font-family: Assistant, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  min-height: 100vh;
  padding: 0;
  font-family: Assistant, sans-serif;
  font-size: 14px;
}

a {
  text-decoration: unset;
  outline: none !important;
  color: black;
}
a:hover {
  color: black;
}

.c-black {
  color: black !important;
}

.td-underline {
  text-decoration: underline;
}

.c-pointer {
  cursor: pointer;
}

.f-10 {
  font-size: 10px;
}
.f-11 {
  font-size: 11px;
}
.f-12 {
  font-size: 12px;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.f-16 {
  font-size: 16px;
}
.f-18 {
  font-size: 18px;
}
.f-20 {
  font-size: 20px;
}
.f-22 {
  font-size: 22px;
}
.f-24 {
  font-size: 24px;
}

.ls-1 {
  letter-spacing: 0.05rem;
}

.ls-2 {
  letter-spacing: 0.1rem;
}

.hover-bold:hover {
  font-weight: bold;
}

.v-center {
  align-items: center;
}
.h-center {
  justify-content: center;
}
.vh-center {
  align-items: center;
  justify-content: center;
}

.c-flex {
  display: flex;
  flex-direction: column;
}

.ml-auto {
  margin-left: auto;
}
.mb-c4 {
  margin-bottom: 2rem !important;
}
.mt-c4 {
  margin-top: 2rem !important;
}
.mt--3 {
  margin-top: -24px !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.w-fit {
  width: fit-content;
}

input,
select {
  height: 40px;
  outline: none !important;
  border: 1px solid #919191;
  border-radius: 0px !important;
  font-size: 13px;
  color: black;
  padding: 5px 14px;
  width: 100%;
  letter-spacing: 0.05px;
  background-color: white;
}
select {
  -webkit-appearance: none;
  background: url(./assets/images/down-arrow.png) no-repeat right 0.45rem center/12px 10px;
}
input.has-error {
  border-color: red;
}
textarea {
  outline: none !important;
  border: 1px solid #919191;
  border-radius: 0px !important;
  font-size: 13px;
  color: black;
  padding: 5px 14px;
}

.form-suffix-icon {
  position: absolute;
  right: 8px;
  width: 24px;
  height: 24px;
  object-fit: contain;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
}

.form-prefix-icon {
  position: absolute;
  left: 8px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  object-fit: contain;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
}
.form-prefix-icon ~ input {
  padding-left: 24px;
}

.pwd-error ul {
  padding-left: 0px;
}

.pwd-error ul .pl-4 {
  padding-left: 1.5rem;
}

.pwd-error ul .success::before {
  content: "";
  background: url(./assets/images/check.svg) no-repeat 50%;
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
}
.pwd-error ul .error {
  color: red;
}
.pwd-error ul .error::before {
  content: "";
  background: url(./assets/images/close-red.svg) no-repeat 50%;
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
}

.popover {
  border-radius: 0px;
  border-color: black;
}
.popover .popover-body {
  font-size: 12px;
  letter-spacing: 0.05rem;
  line-height: 1.6;
  font-family: Assistant, sans-serif;
  color: #000;
  padding: 8px 12px 12px 12px;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  border-right-color: black;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  border-left-color: black;
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  border-bottom-color: black;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  border-top-color: black;
}

.desc-overlay {
  position: absolute;
  text-align: center;
  top: 0px;
  left: -5px;
  bottom: 0px;
  right: -5px;
  padding: 0px 10px;
  background: #ffffffeb;
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}
.section-title + div {
  position: relative;
}
.section-title:hover + div .desc-overlay {
  opacity: 1;
  pointer-events: auto;
}

.react-responsive-modal-container {
  display: flex;
  height: auto;
  overflow-x: auto;
  .react-responsive-modal-modal {
    margin: auto;
  }
}

.login_disable {
  opacity: 0.5;

  input {
    pointer-events: none;
  }

  textarea {
    pointer-events: none;
  }
}

.placeholder-item {
  .fake-text {
    color: #c7c7c7 !important;
  }
  .fake-button {
    background-color: #c7c7c7 !important;
    border-color: #c7c7c7 !important;
    color: white !important;
  }

  .image-wrapper {
    position: relative;
  }

  .image-wrapper::after {
    background-color: hsla(0, 0%, 78%, 0.53);
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}

.placeholder-desc {
  color: #9f9f9f !important;
}

.dropdown.ml-3 {
  margin-left: 16px;
}

.squiggle {
  stroke-dasharray: 450;
  stroke-dashoffset: 450;
  animation: svgDraw 4s linear forwards;
  animation-iteration-count: infinite;
}

@keyframes svgDraw {
  to {
    stroke-dashoffset: 0;
  }
}

.name-avatar {
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}

.ql-toolbar {
  display: none;
}

.ql-container.ql-snow {
  height: 109px;
  border: 1px solid #919191 !important;
  font-family: "Assistant";
}

.expand-icon {
  transform: none;
  transition: transform 0.3s;
}

.expand-icon.flipped {
  transform: rotate(180deg);
}

.dropdown-menu.two-column.show {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // column-count: 2;
  min-width: 440px;

  & > div {
    align-items: flex-start;
    white-space: nowrap;
  }
}

.pl-2 {
  padding-left: 8px !important;
}
.pl-3 {
  padding-left: 16px !important;
}

.loginModalOverlay {
  top: 182px !important;
  bottom: 274px;
}

.loginModalroot {
  top: 182px !important;
  bottom: 274px;
}

.shareModalroot {
  // top: 37px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.createModalroot {
  // top: 20% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginModal {
  min-width: 360px;
  text-align: center !important;

  .login_title {
    padding-top: 48px;
    margin-bottom: 9px;
  }

  .login_description {
    width: 335px;
    display: inline-block;
    margin-bottom: 24px;
  }

  .login_btn {
    margin-bottom: 40px;

    button {
      width: 213px;
      display: initial;
    }
  }
}

.account-menu-menu {
  // background-color: red;
  padding: 10px !important;
  padding-top: 0px !important;

  .login-account-users {
    padding-left: 10px !important;

    .inner-connect {
      display: flex;
      align-items: center;
      flex-direction: row;

      img {
        margin-right: 5px;
        width: 18px;
        height: 18px;
      }
    }
  }
}


.authHeader-ext {
  border: none !important;
}

.authHeader-ext-page {
  padding-top: 0px !important;
}