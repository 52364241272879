.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clear-dropdown button:not(.dropdown-item) {
  background-color: transparent;
  border: none;
  padding: 0px;
  box-shadow: none!important;
}
.clear-dropdown .dropdown-menu {
  border: none;
  padding-left: 4px;
}
.clear-dropdown .dropdown-menu .dropdown-item {
  margin-top: 4px;
  margin-bottom: 8px;
  padding: 0px;
  background-color: white!important;
  width: unset!important;
}
.dropdown-menu {
  border-radius: 0px;
}
.dropdown .menu-item img {
  transition: transform .2s;
}
.dropdown.show .menu-item img {
  transform: rotate(180deg);
}
.clear-dropdown.dropdown .drop-toggler::after {
  transition: transform .2s;
}
.clear-dropdown.dropdown.show .drop-toggler::after {
  transform: rotate(180deg);
}